import React, { useState } from 'react'
import emailjs from 'emailjs-com'
import './Contact.css'
import logo from '../images/zuelchlogo.png' // Adjust the path to your logo
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import miniLogo from '../images/zuelchlogo.png' // Adjust the path to your mini logo

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  })

  const handleSubmit = (e) => {
    e.preventDefault()

    emailjs
      .send(
        'service_dtish92',
        'template_e8ca7wm',
        {
          user_name: formData.name,
          user_email: formData.email,
          user_message: formData.message,
        },
        'S65BlCIx5Pjtj7T4x',
      )
      .then(
        (response) => {
          toast.success(
            <div>
              <img src={miniLogo} alt="Mini Logo" />
              Message sent successfully!
            </div>,
          )
          setFormData({ name: '', email: '', message: '' })
        },
        (error) => {
          console.error('There was an error sending the message:', error)
          toast.error(
            <div>
              <img src={miniLogo} alt="Mini Logo" />
              Error sending message.
            </div>,
          )
        },
      )
  }

  return (
    <div className="container my-5">
      <div className="text-center mb-4">
        <img
          src={logo}
          alt="Logo"
          className="mb-3"
          style={{ width: '150px' }}
        />
        <h2>Contact Us</h2>
        <p>We'd love to hear from you!</p>
      </div>

      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <input
            type="text"
            className="form-control"
            placeholder="Name"
            value={formData.name}
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
          />
        </div>
        <div className="mb-3">
          <input
            type="email"
            className="form-control"
            placeholder="Email"
            value={formData.email}
            onChange={(e) =>
              setFormData({ ...formData, email: e.target.value })
            }
          />
        </div>
        <div className="mb-3">
          <textarea
            className="form-control"
            placeholder="Message"
            rows="5"
            value={formData.message}
            onChange={(e) =>
              setFormData({ ...formData, message: e.target.value })
            }
          />
        </div>
        <div className="text-center">
          <button type="submit" className="btn btn-primary">
            Send
          </button>
        </div>
      </form>
      {/* Toast container */}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        toastClassName="custom-toast"
      />
    </div>
  )
}

export default Contact
