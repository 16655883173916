import React from 'react'
import { Link } from 'react-router-dom' // Import the Link component
import './Home.css'

const Home = () => {
  return (
    <div className="parallax-section">
      <div className="parallax-content">
        <h1>ZuelchWeb</h1>
        <p>
          Welcome to ZuelchWeb – where every pixel and line of code is crafted
          with dedication and passion. As a solo developer and designer, I take
          pride in providing tailored digital solutions that resonate with your
          vision. From sleek web designs to robust web applications, I am
          committed to delivering an unparalleled online experience for your
          business. Let's embark on this digital journey together.
        </p>

        {/* Buttons */}
        <div className="home-buttons">
          <Link to="/contact" className="btn btn-primary mt-3 mr-4">
            Contact Me
          </Link>
          <Link to="/projects" className="btn btn-secondary mt-3">
            View Projects
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Home
