import React, { useState } from 'react'
import { Link } from 'react-router-dom'

const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false)

  // Function to close the navbar
  const handleClose = () => {
    setIsOpen(false)
  }

  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark sticky-top">
      <Link className="navbar-brand mx-3" to="/" onClick={handleClose}>
        ZuelchWeb
      </Link>

      {/* Navbar toggler button */}
      <button
        className="navbar-toggler"
        type="button"
        onClick={() => setIsOpen(!isOpen)}
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>

      <div
        className={`collapse navbar-collapse ${isOpen ? 'show' : ''}`}
        id="navbarNav"
      >
        <ul className="navbar-nav mr-auto">
          <li className="nav-item">
            <Link className="nav-link" to="/" onClick={handleClose}>
              Home
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/about" onClick={handleClose}>
              About
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/contact" onClick={handleClose}>
              Contact
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/projects" onClick={handleClose}>
              Projects
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  )
}

export default NavBar
