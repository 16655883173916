// src/components/Footer.js
import React from 'react'

const Footer = () => {
  return (
    <footer className="bg-dark text-light  p-3">
      <div className="container text-center">
        <p>&copy; {new Date().getFullYear()} ZuelchWeb Development</p>
      </div>
    </footer>
  )
}

export default Footer
