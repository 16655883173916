import React, { useState, useEffect } from 'react'
import './Projects.css'
import { Card, Button } from 'react-bootstrap'
import project3Image from '../images/blogging.PNG'
import project2Image from '../images/ecommerce.PNG'
import project1Image from '../images/tesla.PNG'

const Projects = () => {
  const [projects, setProjects] = useState([])

  useEffect(() => {
    const fetchImagesForProjects = async () => {
      const updatedProjects = await Promise.all(
        initialProjects.map(async (project) => {
          const imageUrl = await fetchImage(project.url)
          return { ...project, imageUrl }
        }),
      )
      setProjects(updatedProjects)
    }

    fetchImagesForProjects()
  }, [])

  const fetchImage = async (url) => {
    try {
      const response = await fetch(url)
      const text = await response.text()
      const match = text.match(/<img [^>]*src="([^"]*)"/i)
      if (match) {
        return match[1]
      }
    } catch (error) {
      console.error('Error fetching image:', error)
    }
    return '' // Return an empty string if image is not found
  }

  const initialProjects = [
    {
      name: 'Tesla Clone Webiste',
      url: 'https://tesla-clonex.web.app/',
      image: project1Image,
    },
    {
      name: 'E-Commerce Store',
      url: 'https://ecommerce-silk-nine.vercel.app/',
      image: project2Image,
    },
    {
      name: 'Blogging Website',
      url: 'https://chimpdux.web.app/',
      image: project3Image,
    },
    // Add more projects here...
  ]

  return (
    <div className="container my-5">
      <h2 className="project-title mx-2">PROJECTS</h2>
      <div className="card-deck mx-1 mt-3">
        {projects.map((project, index) => (
          <Card key={index} className="project-card mt-2">
            <Card.Img variant="top" src={project.image} alt={project.name} />
            <Card.Body>
              <Card.Title className="mx-2">{project.name}</Card.Title>
              <Button variant="primary" href={project.url} target="_blank">
                Visit Project
              </Button>
            </Card.Body>
          </Card>
        ))}
      </div>
    </div>
  )
}

export default Projects
