import React from 'react'
import { Link } from 'react-router-dom'
import './About.css'

const About = () => {
  return (
    <div className="about-section text-light">
      <h1>About Me</h1>
      <p>
        Hello! I'm the face behind ZuelchWeb. As a solo developer, my passion
        lies not just in coding, but in innovating. Every line of code I write
        is a testament to my dedication and fervor for creating dynamic digital
        solutions. But what drives me is more than just the art of programming –
        it's the boundless opportunities to make an impact.
      </p>
      <p>
        Determination fuels my journey. Each project is approached with an
        ambition to exceed expectations, ensuring that my clients not only
        receive a product but a partner who understands and caters to their
        business needs. Let's turn your vision into a digital reality.
      </p>
      <div className="about-buttons mt-5">
        <Link to="/contact" className="btn btn-primary">
          Contact Me
        </Link>
        <Link to="/projects" className="btn btn-secondary ml-3">
          View Projects
        </Link>
      </div>
    </div>
  )
}

export default About
